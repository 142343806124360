/* src/components/merch-banner.css */
.merch-banner {
    background-image: url('/images/merch-banner.jpg'); /* Example banner image */
    background-size: cover;
    background-position: center;
    padding: 50px 0;
    text-align: center;
  }
  
  .merch-banner-title {
    font-size: 3rem;
    color: white;
    font-weight: bold;
  }
  
  .merch-banner-subtitle {
    font-size: 1.5rem;
    color: white;
    margin-top: 10px;
  }
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  height: 100vh;
  background-color: var(--dl-color-theme-primary1);
}

.not-found-container2 {
  max-width: 600px; /* Limit the width for better readability */
  width: 100%;
  padding: 20px;
}

.not-found-text2 {
  font-size: 2rem;
  color: var(--dl-color-custom-secondary1);
  margin-bottom: 20px;
}

.back-home-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: var(--dl-color-theme-primary1);
  background-color: var(--dl-color-custom-secondary1);
  text-decoration: none;
  border-radius: 5px;
}

.back-home-button:hover {
  background-color: var( --dl-color-theme-secondary2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .not-found-text2 {
    font-size: 1.5rem;
  }

  .back-home-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .not-found-text2 {
    font-size: 1.2rem;
  }

  .back-home-button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
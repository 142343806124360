.feature-card3-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card3-text {
  line-height: 28px;
}

@media(max-width: 767px) {
  .feature-card3-feature-card {
    flex-direction: row;
  }
  .feature-card3-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card3-container {
    flex-direction: column;
  }
}

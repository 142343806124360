.pom-info-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-theme-secondary1);
}
.pom-info-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.pom-info-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pom-info-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pom-info-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pom-info-text {
  fill: var(--dl-color-custom-neutral-dark);
  color: var(--dl-color-custom-primary1);
  font-size: 36px;
  font-family: "Fjalla One", system-ui;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}
.pom-info-text1 {
  fill: var(--dl-color-custom-neutral-dark);
  color: var(--dl-color-custom-primary1);
  font-family: "Nunito";
}
.pom-info-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.pom-info-root-class-name {
  background-color: var(--dl-color-custom-neutral-light);
}
@media(max-width: 991px) {
  .pom-info-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
  }
}
@media(max-width: 767px) {
  .pom-info-text {
    font-size: 36px;
  }
}

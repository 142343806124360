@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Fjalla+One&family=Great+Vibes&family=Playwrite+CU&family=Rubik+Mono+One&family=Sacramento&display=swap');
.navbar2-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
}
.navbar2-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar2-image1 {
  height: 5rem;
  width: auto;
}
.navbar2-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar2-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  color: rgb(55, 55, 126);
}
.navbar2-link1 {
  text-decoration: none;
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  color: rgb(55, 55, 126);
}
.navbar2-link2 {
  
  text-decoration: none;
}
.navbar2-link3 {
  text-decoration: none;
}
.navbar2-link4 {
  text-decoration: none;
}
.navbar2-link5 {
  text-decoration: none;
}
.navbar2-burger-menu {
  display: none;
}
.navbar2-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar2-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar2-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar2-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar2-logo {
  height: 3rem;
}
.navbar2-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar2-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar2-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar2-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar2-text {
  display: inline-block;
}
.navbar2-text1 {
  display: inline-block;
}
.navbar2-text2 {
  display: inline-block;
}
.navbar2-text3 {
  display: inline-block;
}
.navbar2-text4 {
  display: inline-block;
}
@media(max-width: 767px) {
  .navbar2-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar2-desktop-menu {
    display: none;
  }
  .navbar2-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar2-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .navbar2-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}

/* General styles */
.classes-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #fff; /* Subtle background color */
}

/* Class Banner */
.event-banner-root-class-name {
  margin-bottom: 30px;
}

/* Classes Section */
.classes-section {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.class-banner-title {
  color: #23297F;
}

.class-banner-subtitle {
  color: #23297F;
}

/* Individual Class Item */
.class-item {
  display: flex;
  flex-direction: row; /* Align image on the left and text on the right */
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.class-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 35px rgba(0, 0, 0, 0.15);
}

/* Class Image */
.class-image-container {
  flex: 1;
  max-width: 350px;
  margin-right: 30px;
}

.class-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover; /* Ensure the image covers the space without distortion */
}

/* Class Info Section */
.class-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.class-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #23297F;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
  line-height: 1.3;
}

.class-description {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.class-location {
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 10px;
}

.class-details {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Timetable Section */
.timetable-root-class-name {
  width: 100%;
  max-width: 1200px;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
  text-align: center;
  border-radius: 10px;
}

.timetable-text {
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 15px;
}

.timetable-content {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 30px;
}



/* Responsive Design */
@media (max-width: 1024px) {
  .classes-section {
    padding: 0 15px;
  }

  .class-item {
    flex-direction: column; /* Stack image and text vertically */
  }

  .class-image-container {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .class-info {
    width: 100%;
    text-align: center;
  }

  .buy-class-button {
    font-size: 1.1rem;
    padding: 12px 25px;
  }
}

@media (max-width: 767px) {
  .class-item {
    flex-direction: column; /* Stack image and text vertically */
  }

  .class-image-container {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .class-info {
    width: 100%;
    text-align: center;
  }

  .buy-class-button {
    font-size: 1.1rem;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .class-item {
    flex-direction: column; /* Stack image and text vertically */
  }

  .class-image-container {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .class-info {
    width: 100%;
    text-align: center;
  }

  .buy-class-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
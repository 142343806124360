/* General styles */
.merch-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;  /* Center the content horizontally */
  justify-content: flex-start;
  box-sizing: border-box;
}

/* Merch Banner */
.merch-banner-root-class-name {
  margin-bottom: 30px;
}

/* Merchandise Grid Section */
.merch-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row by default */
  gap: 30px;
  width: 100%;
  max-width: 1400px; /* Limit max width for larger screens */
  margin: 40px 0;
  padding: 0 20px;
  justify-items: center; /* Center the items */
}

.merch-banner-title{
  color: #23297F;
}

.merch-banner-subtitle{
  color: #23297F;
}

/* Individual Merchandise Item */
.merch-item {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 350px; /* Max width for each item */
}

.merch-item:hover {
  transform: translateY(-5px); /* Hover effect */
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

/* Merchandise Item Image */
.merch-item-image {
  width: 100%;
  height: 250px; /* Increased height */
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.merch-item:hover .merch-item-image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Merchandise Item Title */
.merch-item-title {
  font-size: 1.8rem; /* Larger title size */
  font-weight: 600;
  margin-top: 20px;
  color: #333;
}

/* Merchandise Item Description */
.merch-item-description {
  font-size: 1.2rem;
  color: #666;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 10px;
}

/* Merchandise Item Price */
.merch-item-price {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--dl-color-custom-secondary1);
  margin-top: 10px;
}

/* Add to Cart Button */
.add-to-cart-button {
  background-color: #f0cec7;
  color: white;
  padding: 14px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.3s;
}

.add-to-cart-button:hover {
  background-color: #F1DDD9;
  transform: scale(1.05); /* Slight zoom effect */
}

.add-to-cart-button:active {
  transform: scale(0.98); /* Click effect */
}

/* Responsive Breakpoints */

/* Tablet to Small Desktop */
@media (max-width: 1024px) {
  .merch-items {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row on tablets and smaller screens */
  }

  .merch-item {
    padding: 20px;
  }

  .merch-item-title {
    font-size: 1.7rem;
  }

  .merch-item-description {
    font-size: 1.1rem;
  }

  .merch-item-price {
    font-size: 1.4rem;
  }

  .add-to-cart-button {
    font-size: 1.1rem;
    padding: 12px 25px;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .merch-items {
    grid-template-columns: 1fr; /* 1 item per row on mobile */
    gap: 20px;
  }

  .merch-item {
    padding: 15px;
  }

  .merch-item-title {
    font-size: 1.6rem;
  }

  .merch-item-description {
    font-size: 1rem;
  }

  .merch-item-price {
    font-size: 1.3rem;
  }

  .add-to-cart-button {
    font-size: 1.1rem;
    padding: 12px 22px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Fjalla+One&family=Great+Vibes&family=Playwrite+CU&family=Rubik+Mono+One&family=Sacramento&display=swap');


.private-class-info-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-custom-neutral-light);
}
.private-class-info-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.private-class-info-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.private-class-info-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.private-class-info-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  color: var(--dl-color-custom-primary1); 
}
.private-class-info-text {
  fill: var(--dl-color-custom-neutral-dark);
  color: var(--dl-color-custom-neutral-dark);
  font-size: 48px;
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  color: rgb(55, 55, 126);
}
.private-class-info-text1 {
  fill: var(--dl-color-custom-primary1);
  color: var(--dl-color-custom-primary1);
  font-family: "Nunito";
}
.private-class-info-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
@media(max-width: 1600px) {
  .private-class-info-text {
    color: var(--dl-color-custom-primary1);
 
   
  }
  .private-class-info-text1 {
    color: var(--dl-color-custom-primary1);
  }
}
@media(max-width: 991px) {
  .private-class-info-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
  }
}

/* src/components/google-review.css */
.google-reviews-container {
  margin: 0; /* Remove any default margins */
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text inside the container */
  background-color: #F1DDD9;
  width: 100vw; /* Full viewport width */
  height: 30vh; /* Full viewport height */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.google-reviews-heading {
  text-decoration: none;
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: uppercase;
  font-size: 38px;
  color: rgb(55, 55, 126);
  margin-bottom: 20px; /* Add some space below the heading */
}

.google-reviews-text {
  font-size: 16px;
  margin-bottom: 15px;
}

.google-reviews-link {
  font-size: 18px;
  color: #222684;
  text-decoration: none;
  border: 1px solid #222684;
  border-radius: 5px;
  padding: 10px 15px;
  display: inline-block;
}

.google-reviews-link:hover {
  background-color: #222684;
  color: white;
}
@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Fjalla+One&family=Great+Vibes&family=Playwrite+CU&family=Rubik+Mono+One&family=Sacramento&display=swap');z

.musical-theare-info-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-custom-neutral-light);
}

.musical-theare-info-image-container img {
  width: 100%; /* Make sure this is not forcing the image out of view */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image fits within the container */
}
.musical-theare-info-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.musical-theare-info-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.musical-theare-info-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.musical-theare-info-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  
}
.musical-theare-info-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.musical-theare-info-text {
  fill: var(--dl-color-custom-primary1);
  color: var(--dl-color-custom-primary1);
  font-size: 48px;
  font-family: "Fjalla One", sans-serif;
  font-weight: 500;
  font-style: bold;
  color: rgb(55, 55, 126);
 
}
.musical-theare-info-text1 {
  fill: var(--dl-color-custom-primary1);
  color: var(--dl-color-custom-primary1);
  font-family: "Nunito";
}
@media(max-width: 1600px) {
  .musical-theare-info-text {
    color: var(--dl-color-custom-primary1);
    font-size: 36px;
    font-family: "Fjalla One", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 38px;
  
  }
  .musical-theare-info-text1 {
    color: var(--dl-color-custom-primary1);

  }
}
@media(max-width: 991px) {
  .musical-theare-info-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}

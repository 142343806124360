/* General Styles */
.timetable-container {
  padding: 20px;
  background-color: var(--dl-color-custom-primary1);
  color: #fff;
}

.timetable-max-width {
  max-width: 1200px;
  margin: 0 auto;
}

.timetable-container01 {
  margin-bottom: 20px;
}

.timetable-text {
  font-size: 2em;
  font-weight: bold;
}

.timetable-text01 {
  font-size: 1em;
  color: #fff;
}

.timetable-timeline-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timetable-step {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.timetable-container-step {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timetable-progress {
  height: 4px;
  margin: 10px 0;
}

.timetable-text-date,
.timetable-text-heading {
  font-size: 1.2em;
  font-weight: bold;
}

.timetable-text-content {
  font-size: 1em;
  font-weight: bold;
}

/* Centering the Button Containers */
.timetable-container05,
.timetable-container09,
.timetable-container13 {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; /* Centers the button vertically if needed */
  margin-top: 20px; /* Adds some space above the button */
}

/* Button Styles */
.timetable-navlink {
  display: block;
  padding: 10px 20px;
  color: var(--dl-color-custom-primary1);
  background-color: var(--dl-color-custom-secondary1);
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: bold;
  max-width: 200px; /* Maximum width of the button */
  width: fit-content; /* Adjust width based on content */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
  overflow: hidden; /* Hide overflowed text */
}

.timetable-navlink:hover {
  background-color: var(--dl-color-theme-secondary2);
  color: var(--dl-color-custom-primary1);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .timetable-step {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .timetable-text {
    font-size: 1.5em;
  }

  .timetable-text01 {
    font-size: 0.9em;
  }

  .timetable-text-date,
  .timetable-text-heading {
    font-size: 1em;
  }

  .timetable-text-content {
    font-size: 0.9em;
  }

  .timetable-navlink {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .timetable-text {
    font-size: 1.2em;
  }

  .timetable-text01 {
    font-size: 0.8em;
  }

  .timetable-text-date,
  .timetable-text-heading {
    font-size: 0.9em;
  }

  .timetable-text-content {
    font-size: 0.8em;
  }

  .timetable-navlink {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .timetable-timeline-container {
    padding: 10px;
  }
}